import * as Sentry from '@sentry/browser'
import {
  TemplateLayoutTypeEnum,
  ToReportApi,
} from '@ulysses-inc/harami_api_client'
import type { GetApiType } from '~/adapter/api/useAuthApi'
import type { DBType } from '~/adapter/indexedDB/db'
import type {
  TemplateSchemaLatest,
  TemplateMultipleChoiceSetRelationSchemaLatest,
  TemplateNodeSchemaLatest,
} from '~/adapter/indexedDB/types'
import { convertToModelTemplatePagesWithMultipleChoiceSetUuids } from '~/domain/report/converters/apiToModel/converter'
import { convertNodeWithMedia } from '~/domain/report/converters/offline/toOffline/converter'
import type { Node } from '~/domain/report/model/report/node/node'
import { ErrorWithExtra } from '~/utils/error'
import { retry, sleepSeconds } from '~/utils/promise'
import type {
  GetToReportTemplatesWholeResponse,
  Template,
} from '@ulysses-inc/harami_api_client'

type TemplateNodeSchemaDataWithTemplateId = TemplateNodeSchemaLatest['data'] & {
  templateId: number
}

type TemplateMultipleChoiceSetPair = Pick<
  TemplateMultipleChoiceSetRelationSchemaLatest,
  'templateId' | 'multipleChoiceSetUuid'
>

type MediaToDownload = {
  url: string
  fileUuid: string
  templateId: number
}

// この 10 件は適当な決め。
// 件数を大きくし過ぎると帯域、バックエンドに負荷がかかり過ぎるがそうならないと想定される件数を決めうちで指定。
// 今後の性能検証や運用の結果次第では変わる可能性が大いにある
const templateFetchLimit = 10
const maxRetryCount = 3

const calcBackOff = (triedCount: number) => {
  const rawBackOff = 1.5 ** triedCount * 1000
  // バックオフ全体の 10 % 程度のジッターを入れる
  const backOffWithJitter = rawBackOff * (1 + Math.random() * 0.1)
  return backOffWithJitter
}

class Pagination {
  constructor(
    readonly currentPage: number,
    readonly totalPage: number,
  ) {}

  get hasNextPage() {
    return this.currentPage < this.totalPage
  }

  get progress() {
    // 残り 90 % の進捗を、ページ数に応じて分配する
    return 0.1 + (0.9 * this.currentPage) / this.totalPage
  }

  get next() {
    return this.currentPage + 1
  }
}

const convertToOfflineNodesWithMedia = async (
  templateId: number,
  nodes: Partial<Record<number, Node>>,
) => {
  const mediaToDownload: MediaToDownload[] = []
  const offlineNodes: TemplateNodeSchemaLatest['data']['nodes'] = {}
  for (const node of Object.values(nodes)) {
    if (!node) {
      continue
    }
    const { media, node: convertedNode } = await convertNodeWithMedia(node)
    offlineNodes[node.id] = convertedNode
    if (media) {
      mediaToDownload.push(
        ...media.map(m => ({
          url: m.url,
          fileUuid: m.fileUuid,
          templateId,
        })),
      )
    }
  }

  return { mediaToDownload, offlineNodes }
}

/**
 * ひな形の情報をダウンロードするタスク
 *
 * 指定された範囲のひな形の情報を取得し、ダウンロードする。
 *
 * [リトライの方法]
 * - 一定のかたまりの副作用のある処理群単位で、リトライを行う。一番シンプルと思われるため。
 * - API コールでは、通常 4xx 系が得られた場合はリトライしないことが多いが、この処理系では、4xx が返るのは実装ミスか、絶妙なタイミングでの認証エラーのため、あまり厳密に考えず正常終了しなかったらリトライしている
 *
 */
export class TemplatesDownloadTask {
  private readonly targetPage: number
  private readonly selectedPlaceNodeUuid: string
  private readonly companyId: number
  private readonly db: DBType
  private readonly getApi: GetApiType

  private readonly downloadedMedia: Set<string> = new Set()

  constructor(
    targetPage: number,
    selectedPlaceNodeUuid: string,
    companyId: number,
    db: DBType,
    getApi: GetApiType,
  ) {
    this.targetPage = targetPage
    this.selectedPlaceNodeUuid = selectedPlaceNodeUuid
    this.companyId = companyId
    this.db = db
    this.getApi = getApi
  }

  async run(): Promise<Pagination | Error> {
    const templateResponse = await retry(() => this.fetchTemplates(), {
      maxRetryCount,
      backOff: calcBackOff,
    })

    if (templateResponse instanceof Error) {
      return templateResponse
    }

    const {
      mediaToDownload,
      templateSchemasData,
      templateNodeSchemasData,
      templateMultipleChoiceSetPairs,
    } = await this.convertTemplateResponse(templateResponse.templatesWithProps)

    const saveTemplatesResult = await retry(
      async () => {
        await this.saveTemplates(templateSchemasData, templateNodeSchemasData)
        await this.saveMultipleChoiceSets(
          templateResponse.multipleChoiceSets,
          templateMultipleChoiceSetPairs,
        )
      },
      {
        maxRetryCount,
        backOff: calcBackOff,
      },
    )

    if (saveTemplatesResult instanceof Error) {
      return saveTemplatesResult
    }

    /**
     * 本来は、画像やPDFのダウンロードに失敗した場合にも、ダウンロード全体を失敗としたいが、
     *
     * - 過去にわたって画像ファイルのアップロード処理が厳密な整合性を担保していたか疑わしい
     * - そのような過去にわたって作成されたひな形の画像ダウンロードに失敗しただけで、オフラインモードが利用できないというのは制約が厳しすぎる
     *
     * 画像の一部がダウンロードに失敗しても、オフラインモードにはできるようにし、
     * 記録開始時に保存されたデータの整合性を確認することにする。
     *
     */
    const { failedFileUuids } =
      await this.runMediaDownloadTasks(mediaToDownload)
    if (failedFileUuids.length > 0) {
      Sentry.captureException(
        new ErrorWithExtra(
          'OfflineResourceDownloadError',
          'Failed to download media in templates',
          {
            placeNodeUuid: this.selectedPlaceNodeUuid,
            // https://develop.sentry.dev/sdk/data-handling/#variable-size
            // 「Individual extra data items are limited to 16kB. Total extra data is limited to 256kb.」
            // とのことである。文字コードが何かはわからないが、とりあえず javascript の内部コードである UTF-16 で考える。
            // UUIDv4 は、36文字。「,」区切りで、37文字。よって、uuid １つ当たり、74 bytes と考える。
            // 16,000 / 74 = 216.21621621621622 なので、200 程度に収めれば良い。
            // 失敗した全てがわからなくても、調査には支障がないと考えられるので、ログ送信するのは決めで 50件 にする
            failedFileUuids: String(failedFileUuids.splice(0, 50)),
          },
        ),
      )
    }

    return new Pagination(
      this.targetPage,
      templateResponse.pagination.totalPage,
    )
  }

  private async fetchTemplates() {
    const offset = (this.targetPage - 1) * templateFetchLimit
    return await this.getApi(ToReportApi).getToReportTemplatesWhole({
      placeNodeUUID: this.selectedPlaceNodeUuid,
      offset,
      limit: templateFetchLimit,
    })
  }

  /**
   * APIから取得したひな形を、オフライン用のデータの型に変換および、後続処理に渡す形に変換する
   *
   * @param templatesWithProps
   * @returns
   */
  private async convertTemplateResponse(
    templatesWithProps: GetToReportTemplatesWholeResponse['templatesWithProps'],
  ): Promise<{
    mediaToDownload: MediaToDownload[]
    templateSchemasData: TemplateSchemaLatest['data'][]
    templateNodeSchemasData: TemplateNodeSchemaDataWithTemplateId[]
    templateMultipleChoiceSetPairs: TemplateMultipleChoiceSetPair[]
  }> {
    const mediaToDownload: MediaToDownload[] = []
    const templateSchemasData: TemplateSchemaLatest['data'][] = []
    const templateNodeSchemasData: TemplateNodeSchemaDataWithTemplateId[] = []
    const templateMultipleChoiceSetPairs: TemplateMultipleChoiceSetPair[] = []

    for (const templateWithProps of templatesWithProps) {
      const template = templateWithProps.template
      const templateId = template.id
      if (!templateId) {
        // 異常ケース
        // このケースではシンプルにスキップし、記録開始時などに開始可能なひな形がない旨のエラーとする
        continue
      }
      const multipleChoiceSetUuids = (template.multipleChoiceSets ?? [])
        .map(set => set.uuid)
        .filter((uuid): uuid is string => !!uuid)

      let modelConversionResult: ReturnType<
        typeof convertToModelTemplatePagesWithMultipleChoiceSetUuids
      > = {
        pageNodeIds: [],
        nodes: {},
      }

      // TODO: 表形式にはまだ対応していない & 明確な対応方針も決まっていない。converter でエラー・警告が発生する可能性があるので、nodes の保存はスキップする
      if (template.layoutType !== TemplateLayoutTypeEnum.Grid) {
        modelConversionResult =
          convertToModelTemplatePagesWithMultipleChoiceSetUuids(
            template.pages ?? [],
            multipleChoiceSetUuids,
            true,
          )
      }
      if (modelConversionResult instanceof Error) {
        // 異常ケース
        // このケースではシンプルにスキップし、記録開始時などに開始可能なひな形がない旨のエラーとする
        continue
      }
      const { pageNodeIds, nodes } = modelConversionResult

      const offlineNodesWithMedia = await convertToOfflineNodesWithMedia(
        templateId,
        nodes,
      )

      mediaToDownload.push(...offlineNodesWithMedia.mediaToDownload)

      // ページ配下の情報は別テーブルに保持するため、テンプレート情報からは削除する
      const templateWithEmptyPage: Template = {
        ...template,
        pages: undefined,
      }
      templateSchemasData.push({
        template: templateWithEmptyPage,
        displayProps: {
          isUsedJustForSchedule: templateWithProps.isUsedJustForSchedule,
        },
      })
      templateNodeSchemasData.push({
        templateId,
        pageNodeIds,
        nodes: offlineNodesWithMedia.offlineNodes,
      })

      templateMultipleChoiceSetPairs.push(
        ...multipleChoiceSetUuids.map(uuid => ({
          templateId,
          multipleChoiceSetUuid: uuid,
        })),
      )
    }

    return {
      mediaToDownload,
      templateSchemasData,
      templateNodeSchemasData,
      templateMultipleChoiceSetPairs,
    }
  }

  private async saveTemplates(
    templateSchemasData: TemplateSchemaLatest['data'][],
    templateNodeSchemasData: TemplateNodeSchemaDataWithTemplateId[],
  ) {
    if (templateSchemasData.length === 0) {
      return
    }

    await this.db.transaction(
      'rw',
      [this.db.templates, this.db.templateNodes],
      async tx => {
        await tx.templates.bulkPut(
          templateSchemasData.map(data => ({
            templateId: data.template.id ?? 0, // conversion の部分でチェックしているので、このタイミングで、falsy になることはないはず
            placeNodeId: this.selectedPlaceNodeUuid,
            companyId: this.companyId,
            data,
          })),
        )
        await tx.templateNodes.bulkPut(
          templateNodeSchemasData.map(data => ({
            templateId: data.templateId,
            placeNodeId: this.selectedPlaceNodeUuid,
            companyId: this.companyId,
            data,
          })),
        )
      },
    )
  }

  private async saveMultipleChoiceSets(
    multipleChoiceSets: GetToReportTemplatesWholeResponse['multipleChoiceSets'],
    pairs: TemplateMultipleChoiceSetPair[],
  ) {
    // multipleChoiceSets と pairs 別個に length チェックをしていることに注意する。
    // 本来、pairs に含まれる uuid は、multipleChoiceSets に含まれているはずであり、いづれかのみが空というケースは不正データである。
    // しかし、一部のひな型のみに不正があっただけで、処理全体をクラッシュさせてしまうのは、過剰な制約である。
    // そのため、両者の整合性はここでは一旦無視し、記録を開始するときに厳密にバリデーションをかけることとする

    if (multipleChoiceSets.length > 0) {
      await this.db.transaction(
        'rw',
        [this.db.multipleChoiceSets],
        async tx => {
          await tx.multipleChoiceSets.bulkPut(
            multipleChoiceSets.flatMap(multipleChoiceSet => {
              if (!multipleChoiceSet.uuid) {
                return []
              }
              return [
                {
                  uuid: multipleChoiceSet.uuid,
                  placeNodeId: this.selectedPlaceNodeUuid,
                  companyId: this.companyId,
                  multipleChoiceSet,
                },
              ]
            }),
          )
        },
      )
    }

    if (pairs.length > 0) {
      await this.db.transaction(
        'rw',
        [this.db.templateMultipleChoiceSetRelations],
        async tx => {
          await tx.templateMultipleChoiceSetRelations.bulkPut(
            pairs.map(pair => ({
              ...pair,
              placeNodeId: this.selectedPlaceNodeUuid,
              companyId: this.companyId,
            })),
          )
        },
      )
    }
  }

  private async runMediaDownloadTasks(
    mediaToDownload: MediaToDownload[],
  ): Promise<{
    failedFileUuids: string[]
  }> {
    const remainingTasks = [...mediaToDownload]

    while (remainingTasks.length > 0) {
      const tasks = remainingTasks.splice(0, 10)
      await retry(
        async () => {
          const x = await Promise.allSettled(
            tasks.map(task => this.downloadMedia(task)),
          )
          if (x.some(r => r.status === 'rejected')) {
            throw new Error('Failed to download media')
          }
        },
        {
          maxRetryCount,
          backOff: calcBackOff,
        },
      )

      await sleepSeconds(0.1)
    }

    const failedFileUuids = mediaToDownload
      .filter(m => !this.downloadedMedia.has(m.url))
      .map(m => m.fileUuid)

    return { failedFileUuids }
  }

  private async downloadMedia(mediaInfo: MediaToDownload) {
    if (this.downloadedMedia.has(mediaInfo.url)) {
      return
    }

    const response = await fetch(mediaInfo.url).then(response => {
      if (!response.ok) {
        throw response
      }
      return response.blob()
    })
    const buffer = await response.arrayBuffer()

    await this.db.transaction('rw', [this.db.templateMedia], async tx => {
      await tx.templateMedia.put({
        key: mediaInfo.fileUuid,
        media: {
          underlyingRemoteFile: {
            uuid: mediaInfo.fileUuid,
            url: mediaInfo.url,
          },
          content: {
            buffer,
            mediaType: response.type,
          },
        },
        companyId: this.companyId,
        placeNodeId: this.selectedPlaceNodeUuid,
      })
    })

    this.downloadedMedia.add(mediaInfo.url)
  }
}
