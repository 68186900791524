import type { QuestionType } from '~/domain/report/model/report/node/questionNode/question'
import type { QuestionNodeBase } from '~/domain/report/model/report/node/questionNode/questionNode'
import { convertReportImage } from '../image/convertImage'

export const convertMemo = async <T extends QuestionType>(
  memo: QuestionNodeBase<T>['memo'],
) => {
  return {
    textMemos: memo.textMemos,
    handwrittenMemos: await Promise.all(
      memo.handwrittenMemos.map(convertReportImage),
    ),
    photoMemos: await Promise.all(memo.photoMemos.map(convertReportImage)),
  }
}
