import { useState } from 'react'
import ContentPasteOutlined from '@mui/icons-material/ContentPasteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LogoutIcon from '@mui/icons-material/Logout'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import {
  Drawer,
  MenuList,
  type SxProps,
  type SvgIconTypeMap,
} from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import ListSubheader from '@mui/material/ListSubheader/ListSubheader'
import { useTypedNavigate } from '~/app/routes/hooks'
import { routes } from '~/app/routes/paths'
import HamburgerMenuIcon from '~/assets/logo/HamburgerMenu.svg'
import { NormalMessageDialog } from '~/components/ui/dialog/NormalMessageDialog/NormalMessageDialog'
import { useHaramiReportOfflineMode } from '~/domain/featureFlags/hooks/useHaramiReportOfflineMode'
import { useLogout } from '~/domain/logout/useLogout'
import { PlaceNode } from '~/domain/placeNode/placeNode'
import { useBreakpoint } from '~/hooks/useBreakpoint'
import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'
import { useUserPlaceNodeStore } from '~/stores/place/place'
import { colorTextTertiary } from '~/styles/textColors'
import { Footer } from './components/Footer/Footer'
import {
  HamburgerMenuItemButton,
  HamburgerMenuItemButtonLink,
} from './components/HamburgerMenuItem/HamburgerMenuItem'
import { HamburgerMenuOfflineItem } from './components/HamburgerMenuOffllineItem/HamburgerMenuOfflineItem'
import { OfflineToggleInteractions } from './components/OfflineToggleInteractions/OfflineToggleInteractions'
import { useOfflineModeToggle } from './hooks/useOfflineModeToggle'
import { usePageSelection } from './hooks/usePageSelection'
import type { Theme } from '@emotion/react'
import type { OverridableComponent } from '@mui/material/OverridableComponent'

const styles = {
  list: {
    padding: '8px 0px',
    flexGrow: 1,
  },
  divider: {
    margin: '8px 0px',
  },
  subheader: {
    padding: '24px 0px 0px 24p',
    fontSize: '14px',
    fontWeight: 'bold',
  },
} satisfies Record<string, SxProps<Theme>>

type Props = {
  isOpen: boolean
  onClose: () => void
}

const menuPageLinkItems: {
  title: string
  route:
    | typeof routes.home
    | typeof routes.reports
    | typeof routes.expiredCalendar
  Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string
  }
  showsInOfflineMode: boolean
}[] = [
  {
    title: 'ホーム',
    route: routes.home,
    Icon: EditIcon,
    showsInOfflineMode: true,
  },
  {
    title: 'レポート一覧',
    route: routes.reports,
    Icon: ContentPasteOutlined,
    showsInOfflineMode: false,
  },
  {
    title: '期限切れカレンダー',
    route: routes.expiredCalendar,
    Icon: QueryBuilderIcon,
    showsInOfflineMode: false,
  },
]

const menuLinkPathsAvailableInOfflineMode: string[] = menuPageLinkItems
  .filter(item => item.showsInOfflineMode)
  .map(item => item.route.path)

export function HamburgerMenu({ isOpen, onClose }: Props) {
  const isSmartphone = useBreakpoint() === 'smartphone'
  const isOffline = useOfflineModeStore(state => state.offlineMode)

  // 現場名を取得する
  const placeNode = useUserPlaceNodeStore(state => state.selectedPlaceNode)
  const placeNodeName = placeNode ? PlaceNode.getPlaceNodeName(placeNode) : ''

  // パス名によって選択されているかどうかを判定する
  // 選択されている場合は色を変える
  const { currentPathName, isSelected, getColorForPath } = usePageSelection()

  const { canUseOfflineMode } = useHaramiReportOfflineMode()

  const isOfflineModeToggleAvailable =
    (canUseOfflineMode || isOffline) &&
    menuLinkPathsAvailableInOfflineMode.includes(currentPathName)

  const { toggleOfflineMode, ...propsForInteractions } = useOfflineModeToggle()

  const navigate = useTypedNavigate()
  const { logout } = useLogout()
  const [isOpenPreventingLogoutDialog, setIsOpenPreventingLogoutDialog] =
    useState(false)
  const [
    isOpenPreventingOfflineToggleDialog,
    setIsOpenPreventingOfflineToggleDialog,
  ] = useState(false)

  return (
    <>
      <Drawer open={isOpen} onClose={onClose}>
        <Box
          sx={{
            width: isSmartphone ? '288px' : '320px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            component="img"
            src={HamburgerMenuIcon}
            sx={{
              width: '100%',
            }}
          />
          <MenuList sx={styles.list} component="nav">
            {menuPageLinkItems.map(
              ({ title, route, Icon, showsInOfflineMode }, index) => {
                return (
                  (showsInOfflineMode || !isOffline) && (
                    <HamburgerMenuItemButton
                      key={index}
                      text={title}
                      icon={
                        <Icon sx={{ color: getColorForPath(route.path) }} />
                      }
                      onClick={() => {
                        onClose()
                        // この navigate のパラメータが type safe ではないが、いい方法が思いつかないので一旦妥協する
                        navigate(route, {})
                      }}
                      selected={isSelected(route.path)}
                    />
                  )
                )
              },
            )}
            <ListSubheader sx={styles.subheader} component="div">
              設定
            </ListSubheader>
            {/* NOTE: 多言語は正式版の初期リリース時点では実装しない */}
            {(canUseOfflineMode || isOffline) && (
              <HamburgerMenuOfflineItem
                handleOnClick={() => {
                  if (isOfflineModeToggleAvailable) {
                    onClose()
                    toggleOfflineMode()
                  } else {
                    setIsOpenPreventingOfflineToggleDialog(true)
                  }
                }}
              />
            )}
            <HamburgerMenuItemButtonLink
              text={placeNodeName}
              icon={
                <LocationOnIcon
                  sx={{ color: getColorForPath(routes.places.path) }}
                />
              }
              to={routes.places.buildPath({})}
              selected={isSelected(routes.places.path)}
              disabled={isOffline}
            />
            <Divider sx={styles.divider} component="li" />
            <HamburgerMenuItemButton
              text="ログアウト"
              icon={<LogoutIcon sx={{ color: colorTextTertiary }} />}
              onClick={async () => {
                if (isOffline) {
                  setIsOpenPreventingLogoutDialog(true)
                } else {
                  await logout({ userInitiated: true })
                }
              }}
            />
          </MenuList>
          <NormalMessageDialog
            title="オフラインモードを利用できません"
            contentText="当画面ではオフラインモードを利用できません。ホーム画面に遷移してからオフラインモードを開始してください。"
            isOpen={isOpenPreventingOfflineToggleDialog}
            onOk={() => setIsOpenPreventingOfflineToggleDialog(false)}
          />
          <NormalMessageDialog
            title="ログアウトできません"
            contentText="オフラインモード時はログアウトできません。オフラインモードを解除してからログアウトしてください。"
            isOpen={isOpenPreventingLogoutDialog}
            onOk={() => setIsOpenPreventingLogoutDialog(false)}
          />
          <Footer />
        </Box>
      </Drawer>
      <OfflineToggleInteractions {...propsForInteractions} />
    </>
  )
}
