/**
 *
 * 正式リリースまでの間、FeatureFlags で harami_report へのアクセスを制御する
 */
export const useHaramiReportAccess = () => {
  // TODO: Feature Flags で制御していた時の名残。正式リリース以後関連コードは削除する
  // AppConfig の制御で解放しない理由は、ネットワーク不調などのときに挙動が不安定になりうるため
  // https://kaminashi.atlassian.net/browse/INE-1693
  return { canUse: 'yes' }
}
