import { AppEnvEnum } from '@ulysses-inc/harami_api_client'
import packageJson from '~/../package.json'

export const getBasePath = () => {
  return import.meta.env.VITE_HARAMI_API_ENDPOINT
}

export const getHaramiWebBaseUrl = () => {
  return import.meta.env.VITE_HARAMI_WEB_BASE_URL
}

export const isProductionMode = () => {
  return import.meta.env.MODE === 'prod'
}

export const appEnv = {
  isLocal: import.meta.env.VITE_APP_ENV === AppEnvEnum.LOCAL,
  isDevelopment: import.meta.env.VITE_APP_ENV === AppEnvEnum.DEV,
  isProduction: import.meta.env.VITE_APP_ENV === AppEnvEnum.PRODUCTION,
}

export const getAppVersion = () => {
  return packageJson.version
}
