import { v4 as uuidv4 } from 'uuid'
import type { UploadedImage } from '~/domain/report/model/image/image'
import type { QuestionNode } from '~/domain/report/model/report/node/questionNode/questionNode'
import type { SignatureAnswer } from '~/domain/report/model/report/node/questionNode/signatureQuestionNode/signatureAnswer'
import type { SignatureQuestion } from '~/domain/report/model/report/node/questionNode/signatureQuestionNode/signatureQuestion'
import type { SignatureQuestionNode } from '~/domain/report/model/report/node/questionNode/signatureQuestionNode/signatureQuestionNode'
import { ApiToModelError } from '../error'
import type { ConversionContext } from './types'

export const convertSignatureQuestionNode = (
  context: ConversionContext,
): QuestionNode => {
  const {
    question,
    base: { questionNodeBase, questionBase, recordedAt },
  } = context

  const responseAnswer = question.responseAnswer

  const signatureQuestion: SignatureQuestion = {
    ...questionBase,
    type: 'signature',
  }

  const images: UploadedImage[] =
    responseAnswer?.signatures?.map(image => {
      if (
        image.uuid === undefined ||
        image.recordedAt === undefined ||
        image.url === undefined
      ) {
        throw new ApiToModelError('Image has no uuid, recordedAt, or url.', {
          node: context.base.node,
          image,
        })
      }
      return {
        type: 'uploaded',
        key: uuidv4(),
        recordedAt: image.recordedAt,
        file: {
          uuid: image.uuid,
          url: image.url,
        },
      }
    }) ?? []

  if (images.length > 1) {
    throw new ApiToModelError('Signature question has more than one image.', {
      node: context.base.node,
    })
  }

  let signatureAnswer: SignatureAnswer | undefined = undefined
  if (images.length > 0) {
    signatureAnswer = {
      type: 'signature',
      image: images[0],
      recordedAt,
    }
  }

  const questionNode: SignatureQuestionNode = {
    ...questionNodeBase,
    questionType: 'signature',
    question: signatureQuestion,
    answer: signatureAnswer,
  }

  return questionNode
}
