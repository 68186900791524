import { v4 as uuidv4 } from 'uuid'
import type { UploadedImage } from '~/domain/report/model/image/image'
import type { ImageAnswer } from '~/domain/report/model/report/node/questionNode/imageQuestionNode/imageAnswer'
import type { ImageQuestion } from '~/domain/report/model/report/node/questionNode/imageQuestionNode/imageQuestion'
import type { ImageQuestionNode } from '~/domain/report/model/report/node/questionNode/imageQuestionNode/imageQuestionNode'
import type { QuestionNode } from '~/domain/report/model/report/node/questionNode/questionNode'
import { ApiToModelError } from '../error'
import type { ConversionContext } from './types'

export const convertResultImageQuestionNode = (
  context: ConversionContext,
): QuestionNode => {
  const {
    question,
    base: { questionNodeBase, questionBase, recordedAt },
  } = context

  const responseAnswer = question.responseAnswer

  const imageQuestion: ImageQuestion = {
    ...questionBase,
    type: 'resultImage',
  }

  const images: UploadedImage[] =
    responseAnswer?.resultImages?.map(image => {
      if (
        image.uuid === undefined ||
        image.recordedAt === undefined ||
        image.url === undefined
      ) {
        throw new ApiToModelError('Image has no uuid, recordedAt, or url.', {
          node: context.base.node,
          image,
        })
      }
      return {
        key: uuidv4(),
        type: 'uploaded',
        recordedAt: image.recordedAt,
        file: {
          uuid: image.uuid,
          recordedAt: image.recordedAt,
          url: image.url,
        },
      }
    }) ?? []

  let imageAnswer: ImageAnswer | undefined = undefined
  if (images.length > 0) {
    imageAnswer = {
      type: 'resultImage',
      images,
      recordedAt,
    }
  }

  const questionNode: ImageQuestionNode = {
    ...questionNodeBase,
    questionType: 'resultImage',
    question: imageQuestion,
    answer: imageAnswer,
  }

  return questionNode
}
