import PersonIcon from '@mui/icons-material/Person'
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useSessionStore } from '~/stores/session/session'
import { colorTextSecondary, colorTextTertiary } from '~/styles/textColors'
import type { SxProps, Theme } from '@mui/material'
import packageJson from '~/../package.json'

const styles = {
  item: {
    width: '100%',
    minHeight: '48px',
    alignItems: 'start',
  },
  icon: {
    padding: '3px 12px 0px 16px',
  },
  text: {
    fontSize: '14px',
    color: colorTextSecondary,
    paddingRight: '15px',
  },
} satisfies Record<string, SxProps<Theme>>

export function Footer() {
  const userName = useSessionStore(state => state.user?.name)
  const version = packageJson.version

  return (
    <ListItem disablePadding sx={styles.item}>
      <ListItemIcon sx={styles.icon}>
        <PersonIcon sx={{ color: colorTextTertiary }} />
      </ListItemIcon>
      <ListItemText>
        <Typography sx={styles.text} variant="body2">
          {userName}
        </Typography>
      </ListItemText>
      <ListItemText
        sx={{
          minWidth: '65px',
        }}
      >
        <Typography sx={styles.text} textAlign="right" variant="body2">
          {version}
        </Typography>
      </ListItemText>
    </ListItem>
  )
}
